import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>
                    Curs en directe a través de Zoom UF 5.1 Dret Penal i Processal (6 juliol de
                    07:30 h - 12:30 h)
                </Title>
                <Text>
                    Et presentem el millor curs de reforç per preparar l'examen de Dret Penal.
                    <br />
                    <br />
                    Un any més torna una nova edició i ja s'ha convertit en una formació molt
                    necessària any rere any
                    <br />
                    <br />
                    Un curs de 4 hores en directe a través de ZOOM amb un de les millors docents.
                    <br />
                    <br />
                    Durant el curs ens centrarem en els conceptes clau i susceptibles de sortir en
                    examen.
                    <br />
                    <br />
                    El curs està basat en el temari oficial de la 37a promoció.
                    <br />
                    <br />
                    Dissabte 6 de juliol
                    <br />
                    <br />
                    De 7.30 a 12.30h
                    <br />
                    <br />
                    El repàs final per treure bona nota el dia de l'examen oficial
                    <br />
                    <br />
                    El curs és en directe i quedarà gravat fins al dia de l'examen
                </Text>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/S6UpxRZZXXg"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>*/}
            </div>
        </ProductTemplate>
    );
};
export default Product;
